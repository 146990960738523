import React, {Component} from "react";
import {SlidesData} from "./home";

type Props = {
    data: Array<SlidesData>
}

export class Slides extends Component<Props,{}> {
    render(){
        return(
            <>
                <div id="slides">
                    <div className="container">
                        {this.props.data.map((slide:SlidesData, index:number)=>(
                            <div className={"slide"} key={index}>
                                <div className="img">
                                    <img src={"/img/"+slide.gsx$ico.$t+".svg"} alt=""/>
                                </div>
                                <div className="text">
                                    <h4>{slide.gsx$uppertitle.$t}</h4>
                                    <h2>{slide.gsx$title.$t}</h2>
                                    <p>{slide.gsx$text.$t}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    }
}
