import React from 'react';
import {Home} from "./components/home";

function App() {
  return (
   <>
       <Home/>
   </>
  );
}

export default App;
