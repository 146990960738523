import React, {Component} from "react";
import {GeneralData} from "./home";

type Props = {
    data:GeneralData
}

export class Landing extends Component<Props,{}> {
    render(){
        return(
            <>
                <div id="landing">
                    <div className="header">
                        <div className="container">
                            <div className="fl1"><a href={"mailto:"+this.props.data.gsx$productmail.$t}><img src="/img/mail.svg" alt=""/>{this.props.data.gsx$productmail.$t}</a></div>
                            <div className="fl2"><a href={"tel:"+this.props.data.gsx$producttel.$t}><img src="/img/tel.svg" alt=""/>{this.props.data.gsx$producttel.$t}</a></div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="container">
                            <div className="logo">
                                <img src="/img/logo.svg" alt=""/>
                            </div>
                            <h1>{this.props.data.gsx$title.$t}</h1>
                            <p>{this.props.data.gsx$subtitle.$t}</p>
                        </div>
                    </div>
                    <div className="block landing">
                        <img src="/img/landing.svg" alt=""/>
                    </div>
                </div>
            </>
        )
    }
}
