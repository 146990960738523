import React, {Component} from "react";
import {Landing} from "./landing";
import {Cards} from "./cards";
import {Slides} from "./slides";
import {Contact} from "./contact";
import {Footer} from "./footer";

const makeUrl = (page:number) => {
    return `https://spreadsheets.google.com/feeds/list/1Ak_pnsXcyDW1u3gSIOJH2NVOcU9xHhQiH65p8CvV9ec/${page}/public/values?alt=json`
};

type Value = {
    $t: string
}

export type GeneralData = {
    gsx$title: Value,
    gsx$subtitle: Value,
    gsx$custom: Value,
    gsx$customtitle: Value,
    gsx$customtext: Value,
    gsx$attribution: Value,
    gsx$contacttitle: Value,
    gsx$contactupper: Value,
    gsx$copyright: Value,
    gsx$facebook: Value,
    gsx$linkedin: Value,
    gsx$office: Value,
    gsx$officetext: Value,
    gsx$pricing: Value,
    gsx$pricingmail: Value,
    gsx$pricingtel: Value,
    gsx$productadvice: Value,
    gsx$productmail: Value,
    gsx$producttel: Value,
    gsx$technical: Value,
    gsx$technicalmail: Value,
    gsx$technicaltel: Value,
    gsx$otherproducts: Value,
    gsx$othertitle: Value,
}

const _generalData:GeneralData = {
    gsx$title: {$t: 'The real-time people counting solution for retailers'},
    gsx$subtitle: {$t: 'Our app connects seamlessly to your existing infrastructure and delivers intelligence without ever storing video stream data.'},
    gsx$custom: {$t: 'Custom solutions'},
    gsx$customtitle: {$t: 'Not enough? We can do more!'},
    gsx$customtext: {$t: 'Our software has the ability to do so much more for your business. From heatmaps to distance monitors and shelf analysis. Let us turn your raw video data into actionable data points which can improve your overall business performance. '},
    gsx$attribution: {$t: 'Enterprise Retail Solutions by'},
    gsx$contacttitle: {$t: 'Give us a call and schedule your first meeting today. Our teams are here to help.'},
    gsx$contactupper: {$t: 'Let\'s talk'},
    gsx$copyright: {$t: 'Copyright © 2020 Kountr'},
    gsx$facebook: {$t: 'https://www.facebook.com'},
    gsx$linkedin: {$t: 'https://www.linkedin.com'},
    gsx$office: {$t: 'Office'},
    gsx$officetext: {$t: '28C General C. Budisteanu St., Bucharest, Romania, 010775'},
    gsx$pricing: {$t: 'Pricing & billing'},
    gsx$pricingmail: {$t: 'billing@kountr.ai'},
    gsx$pricingtel: {$t: '+40 753 029 187'},
    gsx$productadvice: {$t: 'Product Advice'},
    gsx$productmail: {$t: 'sales@kountr.ai'},
    gsx$producttel: {$t: '+40 753 029 187'},
    gsx$technical: {$t: 'Technical Support'},
    gsx$technicalmail: {$t: 'support@kountr.ai'},
    gsx$technicaltel: {$t: '+40 753 029 187'},
    gsx$otherproducts: {$t: 'Products'},
    gsx$othertitle: {$t: 'Our Retail Solutions'},
};

export type CardsData = {
    gsx$ico: Value,
    gsx$title: Value,
    gsx$text: Value,
}

const _cardsData:Array<CardsData> = [
    {
        gsx$ico: {$t: 'privacy'},
        gsx$title: {$t: 'Privacy Protection'},
        gsx$text: {$t: 'We only use video-streams to analyse your customer demographics and numbers and store only statistical data used to improve your vectors.'},
    },
    {
        gsx$ico: {$t: 'accuracy'},
        gsx$title: {$t: 'High Accuracy'},
        gsx$text: {$t: 'Our highly accurate solution lets you know in real-time the number of clients so you can measure, analyze, and optimize your services.'},
    },
    {
        gsx$ico: {$t: 'camera'},
        gsx$title: {$t: 'Seemless Integration'},
        gsx$text: {$t: 'Our easy-to-install software allows you to view real-time people-count data on any device, or you can integrate Kountr data into other business applications.'},
    },
    {
        gsx$ico: {$t: 'ai'},
        gsx$title: {$t: 'AI/ML Algorhitm'},
        gsx$text: {$t: 'Our models are being constantly trained and updated to provide accurate customer count and real-time check-out time and more.'},
    },
];

export type SlidesData = {
    gsx$ico: Value,
    gsx$uppertitle: Value,
    gsx$title: Value,
    gsx$text: Value,
}

const _slidesData:Array<SlidesData> = [
    {
        gsx$ico: {$t: 's1'},
        gsx$uppertitle: {$t: 'Time to act'},
        gsx$title: {$t: 'Real-Time Actionable Data'},
        gsx$text: {$t: 'Counting your visitors helps you improve resource allocation and marketing strategies, lowering costs and boosting profits. Our software can also be customized to best suit your needs by adding demographic data analysis, such as gender, approximate age, distance between people and so much more. Just give us a call!'},
    },
    {
        gsx$ico: {$t: 's2'},
        gsx$uppertitle: {$t: 'Faster transactions & happier customers'},
        gsx$title: {$t: 'Automated Queue Management'},
        gsx$text: {$t: 'Knowing precisely how many customers you have waiting in line at the cash registers is essential for your business. Our solution measures this in real time, allowing you to further optimize your services. Minimum waiting time means no more abandoned carts and increased profits.'},
    },
    {
        gsx$ico: {$t: 's3'},
        gsx$uppertitle: {$t: 'Metrics that matter'},
        gsx$title: {$t: 'Traffic Monitoring Made Easy'},
        gsx$text: {$t: 'Measure the number of people within your store at any given time. Our software, can send the information to any in-store display, making real-time data readily available to all your customers.'},
    }
];

export type SolutionsData = {
    gsx$title: Value,
    gsx$link: Value,
}

const _solutionsData:Array<SolutionsData> = [
    {
        gsx$title: {$t: 'Price Automation'},
        gsx$link: {$t: 'http://ispir.alteus.ai'},
    },
    {
        gsx$title: {$t: 'Competition Analysis'},
        gsx$link: {$t: 'http://copio.alteus.ai'},
    },
    {
        gsx$title: {$t: 'Channel Monitoring'},
        gsx$link: {$t: 'http://oraya.alteus.ai'},
    },
    {
        gsx$title: {$t: 'Sales Forecasting'},
        gsx$link: {$t: 'http://prophet.alteus.ai'},
    },
    {
        gsx$title: {$t: 'Recommendations Engine'},
        gsx$link: {$t: 'http://gideon.alteus.ai'},
    }
];

type State = {
    general?: GeneralData,
    cards?: Array<CardsData>,
    slides?: Array<SlidesData>,
    solutions?: Array<SolutionsData>
}

export class Home extends Component<{},State>{

    state:State = {
        general:_generalData,
        cards:_cardsData,
        slides:_slidesData,
        solutions:_solutionsData,
    };

    componentDidMount(): void {
        fetch(makeUrl(1)).then(response => response.json()).then(data =>
            this.setState((prevState: State) => ({...prevState, general: data.feed.entry[0]}))
        );
        fetch(makeUrl(2)).then(response => response.json()).then(data =>
            this.setState((prevState: State) => ({...prevState, cards: data.feed.entry}))
        );
        fetch(makeUrl(3)).then(response => response.json()).then(data =>
            this.setState((prevState: State) => ({...prevState, slides: data.feed.entry}))
        );
        fetch(makeUrl(4)).then(response => response.json()).then(data =>
            this.setState((prevState: State) => ({...prevState, solutions: data.feed.entry}))
        );
    }

    render(){
        return(
            <>
                {this.state.general &&
                    <Landing data={this.state.general} />
                }
                {this.state.cards &&
                    <Cards data={this.state.cards} />
                }
                {this.state.slides &&
                    <Slides data={this.state.slides} />
                }
                {this.state.general &&
                    <div id="custom">
                        <div className="container">
                            <div className="bracket b1">
                                <img src="/img/br1.svg?v3" alt=""/>
                            </div>
                            <div className="content">
                                <h4>{this.state.general?.gsx$custom.$t}</h4>
                                <h2>{this.state.general?.gsx$customtitle.$t}</h2>
                                <p>{this.state.general?.gsx$customtext.$t}</p>
                            </div>
                            <div className="bracket b2">
                                <img src="/img/br2.svg?v3" alt=""/>
                            </div>
                        </div>
                    </div>
                }
                {this.state.general && this.state.solutions &&
                    <Contact data={this.state.general} solutions={this.state.solutions} />
                }
                {this.state.general &&
                    <Footer data={this.state.general} />
                }
            </>
        )
    }
}
