import React, {Component} from "react";
import {GeneralData, SolutionsData} from "./home";

type Props = {
    data: GeneralData,
    solutions: Array<SolutionsData>
}

export class Contact extends Component<Props,{}> {
    render(){
        return(
            <>
                <div id="contact">
                    <div className="container">
                        <div className="mini-logo">
                            <img src="/img/sm-logo.svg" alt=""/>
                        </div>
                        <h1>{this.props.data.gsx$contacttitle.$t}</h1>
                        <div className="cZone">
                            <div className="img">
                                <img src="/img/contact.svg" alt=""/>
                            </div>
                            <div className="details">
                                <div className="cd1">
                                    <h2>{this.props.data.gsx$office.$t}</h2>
                                    <p>{this.props.data.gsx$officetext.$t}</p>
                                </div>
                                <div className="cd2">
                                    <div className="block">
                                        <h2>{this.props.data.gsx$technical.$t}</h2>
                                        <p>
                                            <a href={"mailto:"+this.props.data.gsx$technicalmail.$t}>
                                                {this.props.data.gsx$technicalmail.$t}
                                            </a>
                                        </p>
                                        <p>
                                            <a href={"tel:"+this.props.data.gsx$technicaltel.$t}>
                                                {this.props.data.gsx$technicaltel.$t}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="block">
                                        <h2>{this.props.data.gsx$pricing.$t}</h2>
                                        <p>
                                            <a href={"mailto:"+this.props.data.gsx$pricingmail.$t}>
                                                {this.props.data.gsx$pricingmail.$t}
                                            </a>
                                        </p>
                                        <p>
                                            <a href={"tel:"+this.props.data.gsx$pricingtel.$t}>
                                                {this.props.data.gsx$pricingtel.$t}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="block">
                                        <h2>{this.props.data.gsx$productadvice.$t}</h2>
                                        <p>
                                            <a href={"mailto:"+this.props.data.gsx$productmail.$t}>
                                                {this.props.data.gsx$productmail.$t}
                                            </a>
                                        </p>
                                        <p>
                                            <a href={"tel:"+this.props.data.gsx$producttel.$t}>
                                                {this.props.data.gsx$producttel.$t}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="cd3">
                                <h4>{this.props.data.gsx$otherproducts.$t}</h4>
                                <h2>{this.props.data.gsx$othertitle.$t}</h2>
                                {this.props.solutions.map((solution:SolutionsData, index:number)=>(
                                    <p key={index}>
                                        <a href={solution.gsx$link.$t} target={"_blank"} rel="noopener noreferrer" >{solution.gsx$title.$t}</a>
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
