import React, {Component} from "react";
import {GeneralData} from "./home";

type Props = {
    data: GeneralData,
}

export class Footer extends Component<Props,{}>{
    render(){
        return(
            <>
                <div id="footer">
                    <div className="container">
                        <div className="copy">
                            {this.props.data.gsx$copyright.$t}
                        </div>
                        <div className="powered">
                            {this.props.data.gsx$attribution.$t} <a href="https://www.esolutions.ro" target={"_blank"} rel="noopener noreferrer">eSolutions</a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

