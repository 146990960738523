import React, {Component} from "react";
import {CardsData} from "./home";

type Props = {
    data: Array<CardsData>
}

export class Cards extends Component<Props,{}> {
    render(){
        return(
            <>
                <div id="cards">
                    <div className="container">
                        {this.props.data.map((card:CardsData, index:number) => (
                            <div className={"card"} key={index}>
                                <img src={"/img/"+card.gsx$ico.$t+".svg"} alt=""/>
                                <h3>{card.gsx$title.$t}</h3>
                                <p>{card.gsx$text.$t}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    }
}
